body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
 font-family: 'Inconsolata', monospace;
}

code {
  font-family: 'Inconsolata', monospace;
  background: #ffffff16;
  border: 1px solid #ffffff22;
  padding: 2px 8px;
  border-radius: 2px;
}

p {
  line-height: 1.3;
}
